import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { AuthContext } from '../../pageContext'
import Plan from '../../components/Plan'
import './survey.scss'

const TakeASurvey = () => {
  const { state, actions } = useContext(AuthContext)

  const handleEntry = (type) => {
    if (state.isLoggedIn) {
      if (type === 'researcher') {
        navigate('/portal')
      } else if (type === 'respondent') {
        navigate('/portal')
      } else if (type === 'take') {
        navigate('/portal/take-a-survey')
      }
    } else {
      navigate(`/auth?sign-up?${type}`)
    }
  }

  return (
    <>
      <div>
        <div className="leftSurvey">
          <div style={{ height: '80vh', display: 'flex' }}>
            <div className="entryPlans">
              <div
                onClick={() => handleEntry('respondent')}
                className="entryCards"
              >
                <Plan
                  title={'Respondent'}
                  features={{ free: true, take: true }}
                />
              </div>
              <div
                onClick={() => handleEntry('researcher')}
                className="entryCards"
              >
                <Plan
                  title={'Researcher'}
                  features={{
                    free: true,
                    take: true,
                    personal: true,
                    create: true,
                    personal: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSurvey" />
      </div>
    </>
  )
}

export default TakeASurvey
