import React from 'react'
import CTA from '../CTA'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import './plan.scss'

const Plan = ({ features, title, handleClick }) => {
  return (
    <div className="plans">
      <h3 className="planHeader">{title}</h3>
      <div className="featureList">
        <div className="feature">
          <FontAwesomeIcon
            icon={features.free ? faCheckCircle : faCircleXmark}
            fixedWidth
            color={features.free ? 'green' : 'red'}
          />
          <div className="featureName">Free</div>
        </div>
        <div className="feature">
          <FontAwesomeIcon
            icon={features.take ? faCheckCircle : faCircleXmark}
            fixedWidth
            color={features.take ? 'green' : 'red'}
          />
          <div className="featureName">Take Surveys</div>
        </div>

        <div className="feature">
          <FontAwesomeIcon
            icon={features.create ? faCheckCircle : faCircleXmark}
            fixedWidth
            color={features.create ? 'green' : 'red'}
          />
          <div className="featureName">Create Surveys</div>
        </div>

        <div className="feature">
          <FontAwesomeIcon
            icon={features.personal ? faCheckCircle : faCircleXmark}
            fixedWidth
            color={features.personal ? 'green' : 'red'}
          />
          <div className="featureName">Access Personal Survey Data</div>
        </div>

        {/* <div className="feature">
          <FontAwesomeIcon
            icon={features.all ? faCheckCircle : faCircleXmark}
            fixedWidth
            color={features.all ? 'green' : 'red'}
          />
          <div className="featureName">Access ALL Survey Data</div>
        </div> */}
      </div>
    </div>
  )
}

export default Plan
